import React, { useState } from "react";
import {
    Navbar,
    Button,
  } from "react-bootstrap";

const LanguageDownload = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const emergencyExamplesEN = "https://retool-demo.s3.us-gov-west-1.amazonaws.com/Hurricanes+Helene_Milton+IPAWS+Message+Templates.pdf";
  const emergencyExamplesES = "https://retool-demo.s3.us-gov-west-1.amazonaws.com/Hurricanes+Helene_Milton+IPAWS+Message+Templates_Spanish.pdf";

  const availableLanguages = {
    en: { label: "English", file: emergencyExamplesEN },
    es: { label: "Spanish", file: emergencyExamplesES },
  }

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  return (
    <div className="language-download-container">
      {/* Language Dropdown */}
      <select value={selectedLanguage} onChange={handleLanguageChange} className='language-dropdown'>
        {Object.entries(availableLanguages).map(([code, { label }]) => (
          <option key={code} value={code}>
            {label}
          </option>
        ))}
      </select>
        <Navbar.Brand
          href={availableLanguages[selectedLanguage].file}
          className="brand-emerg"
        >
        <Button
          variant="success"
          size="sm"
        >
          Click Here to Download 
          <br/>
          Emergency Message Sample in
          <br/>
          {availableLanguages[selectedLanguage].label}
        </Button>
      </Navbar.Brand>
    </div>
  );
};

export default LanguageDownload;
